// Components.
import Layout from 'components/Layout/Layout'
import SectionedPage from 'components/SectionedPage'

// Constants.
import { revalidate } from 'lib/constants/revalidate'

// Services.
import pageService from 'lib/services/pixels-headless/posts'

/**
 * Get data for current page by url slug.
 */
export async function getStaticProps({ preview = false, locale = false }) {
  const page = await pageService.getFrontpage(preview, locale)

  return {
    props: {
      page,
      preview,
    },
    revalidate,
  }
}

export default function Home({ page, preview }) {
  return (
    <Layout data={page} preview={preview}>
      <SectionedPage page={page} />
    </Layout>
  )
}
